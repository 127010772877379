import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshRequestConfig,
} from 'axios-auth-refresh';
import MdfApi from '.';

export interface HttpError extends AxiosError {}

const removedUndefinedProperty = <T extends object>(obj: T) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        obj[key] === undefined ||
        obj[key] === null ||
        String(obj[key])?.length === 0
      ) {
        delete obj[key];
      }
    }
  }
};

export default removedUndefinedProperty;

export const makeRequest = async (
  config: AxiosRequestConfig & AxiosAuthRefreshRequestConfig
): Promise<any> => {
  if (!config.url?.includes('620f5854-de2a-4993-a1d7-b5a5a8f09457')) {
    config.baseURL = process.env.REACT_APP_API_BASE_URL;
  }

  if (config.url?.includes('620f5854-de2a-4993-a1d7-b5a5a8f09457')) {
    config.baseURL = process.env.REACT_APP_ERP_BASE_URL;
  }

  config.withCredentials = true;

  const httpRequest = await axios.request({
    ...config,
  });

  return httpRequest;
};

const refreshAuth = async () => await MdfApi.refreshToken();

createAuthRefreshInterceptor(axios, refreshAuth);

axios.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    removedUndefinedProperty(request.params);

    return request;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);
