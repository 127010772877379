import { UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Text from 'src/components/Text';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const useQueryString = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
  };
  const queryString = useQueryString();
  const sessionParam = queryString.get('session');

  return (
    <div className='absolute w-full bg-gray-900 shadow-md px-5 md:px-10 flex flex-col z-10'>
      <div className='flex justify-between items-center h-16'>
        <div className='flex items-center gap-4'>
          <Link to='/'>
            <Text type='label' className='text-primary'>
              {!pathname.includes('cms') &&
                pathname.includes('member') &&
                'Member'}
              {!pathname.includes('cms') &&
                pathname.includes('event') &&
                'Event'}
              {!pathname.includes('cms') &&
                pathname.includes('guest') &&
                !sessionParam &&
                'Guest'}
              {!pathname.includes('cms') &&
                pathname.includes('guest') &&
                sessionParam &&
                'Guest Approval'}
              {!pathname.includes('cms') &&
                pathname.includes('profile') &&
                'Profile'}
              {pathname.includes('cms') && (
                <img
                  className='h-8'
                  src='https://cdn.kmc.solutions/project-statics/kmc-logo-white-with-text.png'
                  alt='logo'
                />
              )}
              {(pathname === '/' || pathname === '/not-found') && 'Home'}
            </Text>
          </Link>

          {pathname.includes('cms') && (
            <div className='flex items-center gap-4'>
              <Link to='/cms'>
                <Text
                  type='label'
                  className={
                    pathname.includes('submission-list')
                      ? 'text-primary'
                      : 'text-white'
                  }
                >
                  Submission List
                </Text>
              </Link>

              <Link to='/cms/event-list'>
                <Text
                  type='label'
                  className={
                    pathname.includes('event') ? 'text-primary' : 'text-white'
                  }
                >
                  Event List
                </Text>
              </Link>
            </div>
          )}
        </div>

        <Button
          shape='circle'
          type='primary'
          onClick={() => push('/profile')}
          icon={<UserOutlined />}
        />
      </div>
    </div>
  );
};

export default Header;
