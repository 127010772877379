import { Spin } from 'antd';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Layout from 'src/components/layout';
import Text from 'src/components/Text';
import Unclear from './declaration-form/result-page/Unclear';
import PageNotFound from './not-found';

const DeclarationForm = lazy(() => import('./declaration-form'));

const Pages: React.FC = () => {
  const DeclarationType = lazy(() => import('./declaration-type'));
  const Profile = lazy(() => import('./profile'));
  const CMSPage = lazy(() => import('./cms'));

  const location = useLocation();

  const useQueryString = () => {
    return new URLSearchParams(location.search);
  };
  const queryString = useQueryString();

  return (
    <Layout>
      <Suspense
        fallback={
          <div className='flex-1 h-full w-full grid place-items-center'>
            <div className='grid place-items-center gap-2'>
              <Spin size='large' />
              <Text type='description'>Loading...</Text>
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path='/' component={DeclarationType} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/cms'>
            {queryString.get('type') && queryString.get('visitorId') ? (
              <Redirect
                to={`/guest?type=guest&visitorId=${queryString.get(
                  'visitorId'
                )}`}
              />
            ) : (
              <Redirect to='/cms/submission-list' />
            )}
          </Route>

          <Route
            exact
            path='/cms/submission-list/:visitId?'
            component={CMSPage}
          />

          <Route exact path='/unclear' component={Unclear} />
          <Route exact path='/cms/event-list/:eventId?' component={CMSPage} />
          <Route path='/not-found' component={PageNotFound} />
          <Route exact path='/:declarationType' component={DeclarationForm} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default Pages;
