import { Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

interface TextProps {
  type?: 'heading1' | 'heading2' | 'heading3' | 'label' | 'description';
  className?: string;
}

const AntdText = Typography.Text;

const Text: React.FC<TextProps> = ({ type, className, children }) => {
  const fontSize = classNames({
    'text-xl md:text-2xl': type === 'heading1',
    'text-sm md:text-md': type === 'heading2',
    'text-md md:text-lg': type === 'heading3',
    'text-xs md:text-sm': type === 'label' || type === 'description',
  });

  const fontWeight = classNames({
    'font-bold': type === 'heading1',
    'font-semibold':
      type === 'heading2' || type === 'heading3' || type === 'label',
  });

  const fontStyle = `${fontSize} ${fontWeight} ${className}`;

  return <AntdText className={fontStyle}>{children}</AntdText>;
};

export default Text;
