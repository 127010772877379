import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import MdfApi from 'src/api';
import kmcLogo from 'src/assets/kmc-solutions-black.png';
import masked from 'src/assets/masked.png';
import Header from './Header';

const Layout: React.FC = ({ children }) => {
  const history = useHistory();
  const { data: session } = useQuery({
    queryKey: 'session',
    queryFn: MdfApi.checkSession,
  });

  const { data: me, isLoading: meIsLoading } = useQuery({
    queryKey: 'me',
    queryFn: MdfApi.me,
    enabled: session?.session ? true : false,
  });

  const { data: lastVisit } = useQuery({
    queryKey: 'lastVisit',
    queryFn: () => MdfApi.getLastVisit(me?.id!),
    enabled: !meIsLoading && me?.id ? true : false,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    if (lastVisit && Object.keys(lastVisit).length > 0) {
      const currentDate = moment().format('LL');
      const lastVisitDate = moment(lastVisit.createdAt).format('LL');

      if (
        currentDate === lastVisitDate &&
        pathname === '/'
        // lastVisit.user.profile
      ) {
        Modal.confirm({
          title: 'Existing Visit Record',
          icon: <ExclamationCircleOutlined />,
          content: 'You already have existing visit record for today',
          okText: 'View Result',
          cancelText: 'Cancel',
          onOk: () => {
            if (!lastVisit.event && !lastVisit.guest) {
              history.push(`/member?type=member&visitorId=${lastVisit.id}`);
            }

            if (lastVisit.event) {
              history.push(`/event?type=event&visitorId=${lastVisit.id}`);
            }

            if (lastVisit.guest) {
              if (lastVisit.guest && !lastVisit.guest.isApproved) {
                history.push(
                  `/guest?type=guest&visitorId=${lastVisit.id}&approval=pending`
                );
              } else {
                history.push(`/guest?type=guest&visitorId=${lastVisit.id}`);
              }
            }
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVisit]);

  return (
    <div className='h-screen w-screen flex bg-gray-200 relative'>
      {!pathname.includes('cms') ? (
        <>
          <div className='hidden w-7/12 bg-white h-full md:flex flex-col justify-center items-center shadow-md'>
            <div className='h-24 flex gap-x-3 items-center'>
              <img className='h-8' src={kmcLogo} alt='KMC LOGO' />
              <h1 className='text-primary font-semibold text-4xl pl-2 mt-0.5 border-l-2 border-primary'>
                MDF
              </h1>
            </div>
            <div className='grid place-items-center'>
              <img className='w-6/12' src={masked} alt='illustration' />
            </div>
          </div>
          <div className='w-full md:w-5/12 relative'>
            <div className='w-full h-full flex flex-col gap-5 items-center relative'>
              <Header />
              <div className='mt-16 flex-1 w-full overflow-y-auto bg-gray-50'>
                {children}
              </div>
            </div>
          </div>{' '}
        </>
      ) : (
        <div className='w-full relative'>
          <div className='w-full h-full flex flex-col gap-5 items-center relative'>
            <Header />
            <div className='mt-16 flex-1 w-full px-5 md:px-10 overflow-y-auto bg-gray-100'>
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
