import { makeRequest } from './axios';
import {
  Buildings,
  Floor,
  IEvent,
  IMe,
  IResult,
  LastVisit,
  Profile,
  Question,
  ResponseData,
  SessionResponse,
  Temperature,
  User,
  VisitorDTO,
  VisitPaging,
  Visits,
} from './interfaces';

interface QrCodes {
  createdAt: string;
  id: string;
  qrName: string;
  qrUrl: string;
  userId: string;
}

class MdfApi {
  static me = async (): Promise<IMe> => {
    const me = await makeRequest({
      url: '/api/users/me',
    });
    return me.data;
  };

  static logout = async (): Promise<any> => {
    const logout = await makeRequest({
      url: '/api/auth/logout',
      method: 'POST',
    });

    return logout.data;
  };

  static changePassword = async (password: string) => {
    const changePassword = await makeRequest({
      url: '/api/auth/change-password',
      method: 'PATCH',
      data: {
        password,
      },
    });

    return changePassword.data;
  };

  static getLastVisit = async (userId: string): Promise<LastVisit> => {
    const getLastVisit = await makeRequest({
      url: `/api/visitors/last-visit?userId=${userId}`,
    });
    return getLastVisit.data;
  };

  static getQuestions = async (): Promise<Question[]> => {
    const getQuestions = await makeRequest({
      url: '/api/surveys',
    });
    return getQuestions.data;
  };

  static getBuildings = async (): Promise<ResponseData<Buildings>> => {
    const getBuildings = await makeRequest({
      url: '/api/buildings',
    });
    return getBuildings.data;
  };

  static getFloors = async (buildingId: number): Promise<Floor[]> => {
    const getFloors = await makeRequest({
      url: `/api/buildings/${buildingId}/floors`,
    });
    return getFloors.data.floors;
  };

  static getEvents = async (): Promise<IEvent[]> => {
    const getEvents = await makeRequest({
      url: '/api/events',
    });
    return getEvents.data;
  };

  static otpCode = async (email: string) => {
    const otpCode = await makeRequest({
      skipAuthRefresh: true,
      url: `/api/auth/otp-code`,
      data: {
        email,
      },
      method: 'POST',
    });
    return otpCode;
  };

  static otpAuth = async (data: { email: string; otp: number }) => {
    const otpAuth = await makeRequest({
      skipAuthRefresh: true,
      url: `/api/auth/otp-auth`,
      data,
      method: 'POST',
    });
    return otpAuth;
  };

  static createVisit = async (data: Partial<VisitorDTO>): Promise<any> => {
    const createVisit = await makeRequest({
      url: '/api/visitors',
      method: 'POST',
      data,
    });
    return createVisit.data;
  };

  static refreshToken = async () => {
    const refreshToken = await makeRequest({
      url: '/api/auth/refresh-token',
      method: 'POST',
    });
    return refreshToken.data;
  };

  static register = async (data: User) => {
    const register = await makeRequest({
      url: '/api/auth/register',
      data,
      method: 'POST',
    });
    return register.data;
  };

  static updateProfile = async (data: Profile) => {
    const updateProfile = await makeRequest({
      url: '/api/users/profile',
      data,
      method: 'PATCH',
    });
    return updateProfile.data;
  };

  static checkSession = async (): Promise<SessionResponse> => {
    const checkSession = await makeRequest({
      url: '/api/auth/jwt-session',
    });
    return checkSession.data;
  };

  static getOneVisit = async (
    visitorId: string,
    type: string
  ): Promise<IResult> => {
    const getOneVisit = await makeRequest({
      url: `/api/visitors/visit/${visitorId}`,
      params: {
        type,
      },
    });

    return getOneVisit.data;
  };

  static approveVisit = async (data: {
    params: { session: string; visitorId: string };
    payload: boolean;
  }) => {
    const approveVisit = await makeRequest({
      url: `/api/visitors/guest-approval`,
      params: {
        session: data.params.session,
        visitorId: data.params.visitorId,
      },
      data: {
        isApproved: data.payload,
      },
      method: 'PATCH',
    });

    return approveVisit.data;
  };

  static login = async (data: { email: string; password: string }) => {
    const login = await makeRequest({
      url: '/api/auth/login',
      data,
      method: 'POST',
    });
    return login.data;
  };

  static uploadQrCode = async (data: FormData): Promise<string> => {
    const uploadQrCode = await makeRequest({
      url: `/api/Azure/blob/vms-upload?apiKey=620f5854-de2a-4993-a1d7-b5a5a8f09457`,
      data,
      method: 'POST',
    });

    return uploadQrCode.data;
  };

  static qrUpload = async (data: { qrName: string; qrUrl: string }) => {
    const qrUpload = await makeRequest({
      url: '/api/users/qr-codes',
      data,
      method: 'POST',
    });

    return qrUpload.data;
  };

  static removeQr = async (qrCodeId: string) => {
    const removeQr = await makeRequest({
      url: '/api/users/qr-codes',
      params: {
        qrCodeId,
      },
      method: 'DELETE',
    });

    return removeQr.data;
  };

  static getQrCodes = async (userId: string): Promise<QrCodes[]> => {
    const getQrCodes = await makeRequest({
      url: '/api/users/qr-codes',
      params: {
        userId,
      },
    });

    return getQrCodes.data;
  };

  static addTemperature = async (data: {
    userId: string;
    temperature: string;
  }) => {
    const addTemperature = await makeRequest({
      url: '/api/visitors/temperatures',
      params: {
        userId: data.userId,
      },
      data: { temperature: data.temperature },
      method: 'POST',
    });

    return addTemperature.data;
  };

  static getSubmissionList = async (params: {
    branchId?: {
      floorId: number;
      floorName: string;
      branchId: number;
      branchName: string;
    }[];
    clear?: boolean;
    searchString?: string;
    dateFrom?: string;
    dateTo?: string;
    page?: number;
    limit: number;
  }): Promise<{ data: Visits[]; pagination: VisitPaging }> => {
    const getSubmissionList = await makeRequest({
      url: '/api/visitors',
      params: {
        _branchId:
          params.branchId && params.branchId.length > 0
            ? JSON.stringify(params.branchId)
            : undefined,
        _search: params.searchString,
        _dateStart: params.dateFrom,
        clear: params.clear,
        _dateEnd: params.dateTo,
        page: params.page,
        limit: params.limit,
      },
    });

    return getSubmissionList.data;
  };

  static updateClearance = async (data: { visitId: string; notes: string }) => {
    const updateClearance = await makeRequest({
      url: '/api/visitors/clear',
      params: {
        visitId: data.visitId,
      },
      data: {
        notes: data.notes,
      },
      method: 'PATCH',
    });

    return updateClearance.data;
  };

  static getTemperature = async (userId?: string): Promise<Temperature> => {
    const getTemperature = await makeRequest({
      url: '/api/visitors/temperatures',
      params: {
        userId,
      },
    });

    return getTemperature.data;
  };

  static closeEvent = async (eventId: string) => {
    const closeEvent = await makeRequest({
      url: '/api/events/',
      params: {
        eventId,
      },
      method: 'PATCH',
    });

    return closeEvent.data;
  };

  static deleteEvent = async (eventId: string) => {
    const deleteEvent = await makeRequest({
      url: '/api/events/',
      params: {
        eventId,
      },
      method: 'DELETE',
    });

    return deleteEvent.data;
  };

  static addEvent = async (data: Partial<IEvent>) => {
    const addEvent = await makeRequest({
      url: '/api/events/',
      data,
      method: 'POST',
    });

    return addEvent.data;
  };

  static lockUser = async (email: string) => {
    const lockUser = await makeRequest({
      url: '/api/users/lock',
      method: 'PATCH',
      params: {
        email,
      },
    });

    return lockUser.data;
  };

  static unlockUser = async (email: string) => {
    const unlockUser = await makeRequest({
      url: '/api/users/unlock',
      method: 'PATCH',
      params: {
        email,
      },
    });

    return unlockUser.data;
  };
}

export default MdfApi;
