import React from 'react';
import unclear from 'src/assets/results/undraw_cancel_u1it.svg';
import Text from 'src/components/Text';

const Unclear: React.FC = () => {
  return (
    <div className='grid h-full place-items-center py-8 gap-4'>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-center'>
          <img className='h-56 md:h-64' alt='facemaskshield' src={unclear} />
        </div>

        <Text type='heading2' className='text-primary text-center'>
          It's better to stay at home for now.
        </Text>
      </div>
    </div>
  );
};

export default Unclear;
