import {
  ServiceWorkerUpdaterProps,
  withServiceWorkerUpdater,
} from '@3m1/service-worker-updater';
import { Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';

const openNotification = (fn: () => void) => {
  const key = `open${Date.now()}`;

  const btn = (
    <Button type='primary' size='small' onClick={fn}>
      Confirm
    </Button>
  );
  notification.open({
    message: 'New version detected',
    description: 'Please click confirm to update!.',
    duration: 50000,
    btn,
    key,
  });
};

const PwaUpdate: React.FC<ServiceWorkerUpdaterProps> = (props) => {
  const [state, setState] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => setState(true), 3000);

    return () => clearTimeout(timer);
  }, [state]);

  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  return newServiceWorkerDetected && state ? (
    <>{openNotification(onLoadNewServiceWorkerAccept)}</>
  ) : null;
};

export default withServiceWorkerUpdater(PwaUpdate);
