import { Route, Switch } from 'react-router-dom';
import PwaUpdate from './components/Pwa-update';
import Pages from './pages';

function App() {
  return (
    <>
      <PwaUpdate />
      <Switch>
        <Route path='/' component={Pages} />
      </Switch>
    </>
  );
}

export default App;
