import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Text from 'src/components/Text';

const PageNotFound: React.FC = () => {
  const { push } = useHistory();

  return (
    <div className='grid h-full place-items-center py-8 gap-4'>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-center'>
          <ExclamationCircleFilled className='text-primary text-8xl' />
        </div>

        <Text type='heading1' className='text-primary text-center'>
          404 Page not Found.
        </Text>

        <Text type='heading2' className='text-primary text-center'>
          Sorry we couldn't find this page.
        </Text>

        <Button type='primary' onClick={() => push('/')}>
          Return to Home
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
